<template>
    <div class="result">
        <h2>{{ name }}</h2>

        <div class="result-box" v-if="loaded">
            <div class="result-box__left">
                <img :alt="name" :src="src" class="result__image">
            </div>
            <div class="result-box__right">
                <input type="number" v-model="maxColors">

                <ul class="result-list">
                    <li v-for="(entry, index) in filteredColors" :key="index">
                        <div
                            class="result-list__color"

                            :title="`RGB: ${entry.color.r}, ${entry.color.g}, ${entry.color.b}`"
                            :style="{ backgroundColor: `rgb(${entry.color.r}, ${entry.color.g}, ${entry.color.b})` }"
                        ></div>
                        <span class="result-list__label" :title="`Pixels: ${ entry.count }`">{{ (entry.count * 100 / total).toFixed(2) }}%</span>
                    </li>
                </ul>
            </div>
        </div>

        <!-- Always render canvas -->
        <canvas class="result__canvas"></canvas>
    </div>
</template>

<script>
export default {
    name: 'Form',
    props: {
        name: String,
        url: String,
    },

    data: () => ({
        loaded: false,
        src: null,
        canvas: null,
        maxColors: 3,

        total: 0,
        list: null,
    }),

    async mounted() {
        this.canvas = this.$el.querySelector('canvas');

        const img = new Image();

        img.onload = () => {
            this.src = this.url;

            // Resize canvas
            const { height, width } = img;

            this.canvas.height = height;
            this.canvas.width = width;

            // Load image to canvas
            const context = this.canvas.getContext('2d');

            context.drawImage(img, 0, 0);

            this.list = this.generateResultSet(this.canvas);
            this.loaded = true;
        };

        img.src = this.url;
    },

    computed: {
        filteredColors() {
            return this.list.slice(0, this.maxColors);
        },
    },

    methods: {
        generateResultSet(canvas) {
            const context = canvas.getContext('2d');
            const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
            const buffer = new Uint32Array(imageData.data.buffer);

            const stats = {};

            // Create statistics
            for (let i = 0; i < buffer.length; i += 1) {
                const key = `${buffer[i] & 0xffffff}`;

                if (!stats[key]) {
                    stats[key] = {
                        color: {
                            r: (key & 0xff),
                            g: (key & 0xff00) >>> 8,
                            b: (key & 0xff0000) >>> 16,
                        },
                        count: 0,
                    };
                }

                stats[key].count += 1;

                this.total += 1;
            }

            // Order by count
            const colors = Object.values(stats);

            colors.sort((a, b) => b.count - a.count);

            return colors;
        },
    },
};
</script>

<style lang="scss" scoped>
.result {
    &__image {
        border: 1px solid #ccc;
        border-radius: 5px;

        max-height: 500px;
        max-width: 500px;
    }

    &__canvas {
        display: none;
    }
}

.result-list {
    text-align: left;
    padding: 0;
    list-style-type: none;

    &__color {
        display: inline-block;
        height: calc(1em - 2px);
        width: calc(1em - 2px);

        border: 1px solid #fff;
        border-radius: 50%;
        box-shadow: 0 0 0 1px #000;

        margin-left: 5px;
        margin-right: 10px;
    }
}

.result-box {
    display: flex;
    justify-content: center;

    &__left {
        margin-right: 1em;
    }
}

</style>
