<template>
    <div id="app">
        <a href="/">
            <img alt="Logo" src="./assets/logo.png">
        </a>
        <h1>ColorSpy</h1>

        <Form @files="onFiles" v-if="!files.length"/>

        <div v-for="(file, index) in files" :key="index">
            <Result :name="file.name" :url="file.url" />
        </div>
    </div>
</template>

<script>
import Form from './components/Form.vue';
import Result from './components/Result.vue';

export default {
    name: 'App',
    components: {
        Form,
        Result,
    },

    data: () => ({
        files: [],
    }),

    methods: {
        onFiles(data) {
            this.files = data.files;
        },
    },
};
</script>

<style lang="scss">
html, body {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #333;
}

#app {
    margin-top: 60px;
    text-align: center;
}
</style>
