<template>
    <div class="form">
        <p>
            Please select an image to be processed.
        </p>

        <input
            type="file"
            accept="image/*"
            multiple

            @change="fileChanged"
        >
    </div>
</template>

<script>
export default {
    name: 'Form',

    methods: {
        fileChanged(event) {
            const selected = event.target.files || event.dataTransfer.files;

            if (!selected) {
                return;
            }

            const files = [];
            selected.forEach((file) => {
                files.push({
                    name: file.name,
                    url: URL.createObjectURL(file),
                });
            });

            this.$emit('files', { files });
        },
    },
};
</script>
